const candidate = [
  {
    key: 'mainMenuKey1',
    label: 'Dashboard',
    localeLabel: {
      EN: 'Dashboard',
      SK: 'Dashboard Sk'
    },
    link: '/candidate/dashboard'
  },
  {
    key: 'mainMenuKey2',
    label: 'Search Job',
    localeLabel: {
      EN: 'Search Job',
      SK: 'Search Job Sk'
    },
    link: '/candidate/search-job'
  },
  {
    key: 'mainMenuKey5',
    label: 'Search Project',
    localeLabel: {
      EN: 'Search Project',
      SK: 'Search Project Sk'
    },
    link: '/candidate/search-project'
  },
  {
    key: 'mainMenuKey3',
    label: 'History of Requests',
    localeLabel: {
      EN: 'History of Requests',
      SK: 'History of Requests Sk'
    },
    link: '/candidate/requests-history'
  },
  {
    key: 'mainMenuKey4',
    label: 'Profile Settings',
    localeLabel: {
      EN: 'Profile Settings',
      SK: 'Profile Settings Sk'
    },
    link: '/candidate/profile-settings'
  }
]

const employer = [
  {
    key: 'mainMenuKey1',
    label: 'Dashboard',
    localeLabel: {
      EN: 'Dashboard',
      SK: 'Dashboard Sk'
    },
    link: '/employer/dashboard'
  },
  {
    key: 'mainMenuKey2',
    label: 'My Jobs',
    localeLabel: {
      EN: 'My Jobs',
      SK: 'My Jobs Sk'
    },
    link: '/employer/my-jobs'
  },
  {
    key: 'mainMenuKey3',
    label: 'My Candidates',
    localeLabel: {
      EN: 'My Candidates',
      SK: 'My Candidates Sk'
    },
    link: '/employer/my-candidates'
  },
  {
    key: 'mainMenuKey4',
    label: 'Blind Candidate Profiles',
    localeLabel: {
      EN: 'Blind Candidate Profiles',
      SK: 'Blind Candidate Profiles Sk'
    },
    link: '/employer/blind-candidate-profiles'
  },
  {
    key: 'mainMenuKey5',
    label: 'Profile Settings',
    localeLabel: {
      EN: 'Profile Settings',
      SK: 'Profile Settings Sk'
    },
    link: '/employer/profile-settings'
  },
  // {
  //   key: 'mainMenuKey6',
  //   label: 'Profile Settings',
  //   localeLabel: {
  //     EN: 'Pricing',
  //     SK: 'Pricing Sk'
  //   },
  //   link: '/pricing'
  // },
  {
    key: 'mainMenuKey7',
    label: 'Get in Touch',
    localeLabel: {
      EN: 'Get in Touch',
      SK: 'Get in Touch Sk'
    },
    link: '/contact-us'
  }
]

const employerFree = [
  {
    key: 'mainMenuKey1',
    label: 'Dashboard',
    localeLabel: {
      EN: 'Dashboard',
      SK: 'Dashboard Sk'
    },
    link: '/employer/dashboard'
  },
  {
    key: 'mainMenuKey2',
    label: 'My Jobs',
    localeLabel: {
      EN: 'My Jobs',
      SK: 'My Jobs Sk'
    },
    link: '/employer/my-jobs'
  },
  {
    key: 'mainMenuKey3',
    label: 'My Candidate Profile',
    localeLabel: {
      EN: 'My Candidate Profile',
      SK: 'My Candidate Profile Sk'
    },
    link: '/employer/my-candidates'
  },
  {
    key: 'mainMenuKey4',
    label: 'Applicants',
    localeLabel: {
      EN: 'Applicants',
      SK: 'Applicants Sk'
    },
    link: '/employer/applicants'
  },
  {
    key: 'mainMenuKey5',
    label: 'Profile Settings',
    localeLabel: {
      EN: 'Profile Settings',
      SK: 'Profile Settings Sk'
    },
    link: '/employer/profile-settings'
  }
  // {
  //   key: 'mainMenuKey6',
  //   label: 'Profile Settings',
  //   localeLabel: {
  //     EN: 'Pricing',
  //     SK: 'Pricing Sk'
  //   },
  //   link: '/pricing'
  // }
]

const recruiter = [
  {
    key: 'mainMenuKey1',
    label: 'Dashboard',
    localeLabel: {
      EN: 'Dashboard',
      SK: 'Dashboard Sk'
    },
    link: '/recruiter/dashboard'
  },
  {
    key: 'mainMenuKey8',
    label: 'Assigned Jobs',
    localeLabel: {
      EN: 'Assigned Jobs',
      SK: 'Assigned Jobs Sk'
    },
    link: '/recruiter/assigned-jobs'
  },
  {
    key: 'mainMenuKey9',
    label: 'Blind Candidate Profiles',
    localeLabel: {
      EN: 'Blind Candidate Profiles',
      SK: 'Blind Candidate Profiles Sk'
    },
    link: '/recruiter/blind-candidate-profiles'
  },
  {
    key: 'mainMenuKey2',
    label: 'All Jobs',
    localeLabel: {
      EN: 'All Jobs',
      SK: 'All Jobs Sk'
    },
    link: '/recruiter/all-jobs'
  },
  {
    key: 'mainMenuKey3',
    label: 'My Jobs',
    localeLabel: {
      EN: 'My Jobs',
      SK: 'My Jobs Sk'
    },
    link: '/recruiter/my-jobs'
  },
  {
    key: 'mainMenuKey6',
    label: 'Applicants',
    localeLabel: {
      EN: 'Applicants',
      SK: 'Applicants Sk'
    },
    link: '/recruiter/applicants'
  },
  {
    key: 'mainMenuKey7',
    label: 'Applicants Search',
    localeLabel: {
      EN: 'Applicants Search',
      SK: 'Applicants Search Sk'
    },
    link: '/recruiter/candidate-search'
  }
]

const admin = [
  {
    key: 'mainMenuKey1',
    label: 'Jobs',
    localeLabel: {
      EN: 'Jobs',
      SK: 'Jobs Sk'
    },
    link: '/admin/jobs'
  },
  {
    key: 'mainMenuKey2',
    label: 'Blind Candidate Profiles',
    localeLabel: {
      EN: 'Blind Candidate Profiles',
      SK: 'Blind Candidate Profiles Sk'
    },
    link: '/admin/projects'
  },
  {
    key: 'mainMenuKey3',
    label: 'Recruiters',
    localeLabel: {
      EN: 'Recruiters',
      SK: 'Recruiters Sk'
    },
    link: '/admin/recruiters'
  },
  {
    key: 'mainMenuKey4',
    label: 'Employers',
    localeLabel: {
      EN: 'Employers',
      SK: 'Employers Sk'
    },
    link: '/admin/employers'
  },
  {
    key: 'mainMenuKey5',
    label: 'Applicants',
    localeLabel: {
      EN: 'Applicants',
      SK: 'Applicants Sk'
    },
    link: '/admin/candidates'
  },
  {
    key: 'mainMenuKey6',
    label: 'Profile Settings',
    localeLabel: {
      EN: 'Profile Settings',
      SK: 'Profile Settings Sk'
    },
    link: '/admin/profile-settings'
  }
]
// const defaultMenu = null
const defaultMenu = [
  {
    key: 'mainMenuKey1',
    label: 'Home',
    localeLabel: {
      EN: 'Home',
      SK: 'Home Sk'
    },
    link: '/home'
  },
  {
    key: 'mainMenuKey2',
    label: 'Jobs',
    localeLabel: {
      EN: 'Jobs',
      SK: 'Jobs Sk'
    },
    link: '/search-job'
  },
  {
    key: 'mainMenuKey3',
    label: 'Available Candidates',
    localeLabel: {
      EN: 'Available Candidates',
      SK: 'Available Candidates Sk'
    },
    link: '/available-candidates'
  },
  {
    key: 'mainMenuKey4',
    label: 'Get in Touch',
    localeLabel: {
      EN: 'Get in Touch',
      SK: 'Get in Touch Sk'
    },
    link: '/contact-us'
  }
  // {
  //   key: 'mainMenuKey3',
  //   label: 'Features',
  //   localeLabel: {
  //     EN: 'Features',
  //     SK: 'Features Sk'
  //   },
  //   link: '/features'
  // },
  // {
  //   key: 'mainMenuKey4',
  //   label: 'About Us',
  //   localeLabel: {
  //     EN: 'About Us',
  //     SK: 'About Us Sk'
  //   },
  //   link: '/about-us'
  // }
]

const adminDropdown = [
  {
    key: 'mainMenuKey6',
    label: 'Profile Settings',
    localeLabel: {
      EN: 'Profile Settings',
      SK: 'Profile Settings Sk'
    },
    link: '/admin/profile-settings',
    icon: 'Settings'
  }
]

//const candidateDropdown = null

const candidateDropdown = [
  {
    key: 'dropdownKey1',
    label: 'Profile Settings',
    localeLabel: {
      EN: 'Profile Settings',
      SK: 'Profile Settings Sk'
    },
    link: '/candidate/profile-settings',
    icon: 'Settings'
  },
  {
    key: 'dropdownKey2',
    label: 'Profile Preview',
    localeLabel: {
      EN: 'Profile Preview',
      SK: 'Profile Preview Sk'
    },
    link: '/candidate/profile-preview',
    icon: 'User'
  }
]

const employerDropdown = [
  {
    key: 'dropdownKey1',
    label: 'Billing Portal',
    localeLabel: {
      EN: 'Billing Portal',
      SK: 'Billing Portal Sk'
    },
    link: '/employer/billing-portal',
    icon: 'Settings'
  },
  {
    key: 'dropdownKey2',
    label: 'Profile Preview',
    localeLabel: {
      EN: 'Profile Preview',
      SK: 'Profile Preview Sk'
    },
    link: '/employer/profile-preview',
    icon: 'User'
  }
]
const recruiterDropdown = null

const defaultDropdown = null

export const dropdownMenu = [
  defaultDropdown,
  adminDropdown,
  candidateDropdown,
  employerDropdown,
  recruiterDropdown,
  employerDropdown
]

export const menu = [
  defaultMenu,
  admin,
  candidate,
  employer,
  recruiter,
  employerFree
]

export const defaultRoute = [
  '/',
  '/admin/jobs',
  '/candidate/dashboard',
  '/employer/dashboard',
  '/recruiter/dashboard',
  '/employer/dashboard'
]
