import { CHANGE_LANGUAGE } from '../../types/locale'

const initialState = {
  language: localStorage.getItem('locale')
    ? localStorage.getItem('locale')
    : 'EN'
}

export const locale = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE: {
      return {
        ...state,
        language: action.payload
      }
    }
    default:
      return state
  }
}

export default locale
