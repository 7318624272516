import { USER_TYPES } from '../../types/user'
import { AUTH_TYPES } from '../../types/auth'

const initialState = {
  userData: null,
  error: null,
  isLoading: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPES.IS_USER_DATA_LOADING:
      return { isLoading: true, error: null, userData: null }
    case USER_TYPES.HANDLE_USER_DATA_SUCCESS:
      return { isLoading: false, error: null, userData: action.user }
    case USER_TYPES.HANDLE_USER_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case AUTH_TYPES.LOGOUT:
      return initialState
    default:
      return state
  }
}

export default authReducer
