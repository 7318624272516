// ** Logo
import logo from '@src/assets/images/logo/logo.png'
const FlexidoLogo = require(`@src/assets/images/logo/logo.svg`).default

const SpinnerComponent = () => {
  return (
    <></>
    // <div className='fallback-spinner w-100 vh-100'>
    //   <img style={{ height: '50px'}} src={FlexidoLogo} />
    // </div>
  )
}

export default SpinnerComponent
